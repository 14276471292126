@font-face {
  font-family: "Brandon Grotesque";
  src: url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-regular.woff2") format("woff2"), url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-regular.woff") format("woff"), url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Brandon Grotesque";
  src: url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-semibold.woff2") format("woff2"), url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-semibold.woff") format("woff"), url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Brandon Grotesque";
  src: url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-bold.woff2") format("woff2"), url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-bold.woff") format("woff"), url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Brandon Grotesque";
  src: url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-black.woff2") format("woff2"), url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-black.woff") format("woff"), url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: fallback;
}
html {
  --magic-primary: #00eaab;
  --magic-secondary: #292d39;
  --magic-tertiary: #231f20;
  --magic-background: #fbfbfb;
  --magic-text: #292d39;
  --magic-text-primary: #292d39;
  --magic-caption: #292d39;
  --magic-primary-contrast: var(--magic-light, #fff);
  --magic-secondary-contrast: var(--magic-light, #fff);
  --magic-success: #009b6e;
  --magic-error: #ff2334;
  --magic-alert: #ff9f32;
  --magic-alert-contrast: var(--magic-light, #fff);
  --magic-light-contrast: #231f20;
  --magic-field-border: #c6c6c6;
  --magic-header: #231f20;
  --magic-badge-background: #00eaab;
  --magic-medium: #292d39;
  --magic-button-primary-darker: #04dba0;
  --magic-button-medium-darker: #262a35;
  --magic-button-light-darker: #efefef;
  --magic-button-success-darker: #039167;
  --magic-button-error-darker: #ef2131;
  --magic-button-alert-darker: #f0952f;
  --magic-button-primary-transparent: rgba(0, 234, 171, 0.125);
  --magic-button-disabled-darker: #e4e4e4;
  --magic-button-alert-darker: #f0952f;
  --magic-button-disabled-transparent: #e4e4e4;
  --magic-button-light-transparent: transparent;
  --magic-checkbox-background: #00eaab;
  --magic-checkbox-border: #292d39;
  --magic-checkbox-hover-border: #00eaab;
  --magic-checkbox-focus-border: #00eaab;
  --magic-checkbox-checked-border: #00eaab;
  --magic-checkbox-disabled-border: #e4e4e4;
  --magic-checkbox-disabled-label: #e4e4e4;
  --magic-checkbox-disabled-background: #e4e4e4;
  --magic-radio-border: #231f20;
  --magic-radio-hover-border: #00eaab;
  --magic-radio-focus-border: #00eaab;
  --magic-radio-checked-border: #00eaab;
  --magic-radio-background: #00eaab;
  --magic-switch-checked-background: #00eaab;
  --magic-switch-hover-border: #00eaab;
  --magic-font-family: "Brandon Grotesque";
  --magic-header-font-family: "Brandon Grotesque";
  --magic-dropdown-padding-vertical: 12px;
  --magic-gradient-1: #00eaab linear-gradient(135deg, #00eaab, #241831);
  --magic-step-progress-bar-color: rgba(0, 234, 171, 0.3);
  --magic-account-menu-background: #212121;
  --magic-coin-balance-icon-color: #00eaab;
  --magic-shop-item-icon-color: #00eaab;
  --magic-shop-balance-icon-color: #00eaab;
  --magic-game-navigation-landscape-width: 98px;
  --magic-element-default-width: 344px;
  --magic-promotions-accordion-toggle-background: #231f20;
  --magic-offer-card-size: var(--magic-element-default-width, 288px);
}

html.color-theme-in-transition,
html.color-theme-in-transition *,
html.color-theme-in-transition *::before,
html.color-theme-in-transition *::after {
  transition: all 0.75s;
  transition-delay: 0;
}

html[data-theme=dark] {
  --magic-tertiary: black;
  --magic-tertiary-contrast: #b0b0b0;
  --magic-disabled: #8b8b8b;
  --magic-background: #1a1a1a;
  --magic-background-light: #343434;
  --magic-background-dark: #1a1a1a;
  --magic-text: #b0b0b0;
  --magic-caption: #c6c6c6;
  --magic-header: #c6c6c6;
  --magic-shadow: rgba(0, 0, 0, 0.75);
  --magic-shadow-light: rgba(0, 0, 0, 0.45);
  --magic-shadow-lighter: rgba(0, 0, 0, 0.25);
  --magic-input-border: #4e4e4e;
  --magic-dropdown-border: #4e4e4e;
  --magic-selects-border: #4e4e4e;
  --magic-form-field-message-background: #252525;
  --magic-password-indicator-background: #252525;
}

@media (prefers-color-scheme: dark) {
  html:not([data-theme=light]) {
    --magic-tertiary: #009e73;
    --magic-tertiary-contrast: #b0b0b0;
    --magic-disabled: #8b8b8b;
    --magic-background: #1a1a1a;
    --magic-background-light: #343434;
    --magic-background-dark: #1a1a1a;
    --magic-text: #b0b0b0;
    --magic-caption: #c6c6c6;
    --magic-header: #c6c6c6;
    --magic-shadow: rgba(0, 0, 0, 0.75);
    --magic-shadow-light: rgba(0, 0, 0, 0.45);
    --magic-shadow-lighter: rgba(0, 0, 0, 0.25);
    --magic-input-border: #4e4e4e;
    --magic-dropdown-border: #4e4e4e;
    --magic-selects-border: #4e4e4e;
    --magic-form-field-message-background: #252525;
    --magic-password-indicator-background: #252525;
  }
}