.zendesk-loader[data-v-2698967a] {
  height: calc(100vh - calc(var(--magic-offset-top) + var(--magic-offset-bottom)));
  width: 100%;
  position: fixed;
  z-index: 999997;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
}
@media (min-width: 1024px) {
.zendesk-loader[data-v-2698967a] {
    width: 374px;
    height: 374px;
    bottom: var(--magic-space, 16px);
    right: var(--magic-space, 16px);
    border-radius: var(--magic-border-radius, 4px);
}
}
.loader[data-v-2698967a] {
  --magic-loader-stroke: var(--magic-zendesk-loader-stroke, var(--magic-light, #fff));
}