.ratio[data-v-b6483216] {
  width: calc(100vw - var(--magic-space--xx-large, 128px));
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 1.3333333333);
}
.ratio[data-v-b6483216]::before {
  content: " ";
  display: block;
}
.ratio.has-2[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 2.6666666667);
}
.ratio[data-v-b6483216]::before {
  padding-top: 75%;
}
.ratio.has-2[data-v-b6483216]::before {
  padding-top: 37.5%;
}
.ratio--16x9[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 1.7777777778);
}
.ratio--16x9.has-2[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 3.5555555556);
}
.ratio--16x9[data-v-b6483216]::before {
  padding-top: 56.25%;
}
.ratio--16x9.has-2[data-v-b6483216]::before {
  padding-top: 28.125%;
}
.ratio--533x300[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 1.7766666667);
}
.ratio--533x300.has-2[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 3.5533333333);
}
.ratio--533x300[data-v-b6483216]::before {
  padding-top: 56.2851782364%;
}
.ratio--533x300.has-2[data-v-b6483216]::before {
  padding-top: 28.1425891182%;
}
.ratio--17x13[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 1.3076923077);
}
.ratio--17x13.has-2[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 2.6153846154);
}
.ratio--17x13[data-v-b6483216]::before {
  padding-top: 76.4705882353%;
}
.ratio--17x13.has-2[data-v-b6483216]::before {
  padding-top: 38.2352941176%;
}
.ratio--128x101[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 1.2673267327);
}
.ratio--128x101.has-2[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 2.5346534653);
}
.ratio--128x101[data-v-b6483216]::before {
  padding-top: 78.90625%;
}
.ratio--128x101.has-2[data-v-b6483216]::before {
  padding-top: 39.453125%;
}
.ratio--3x4[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 0.75);
}
.ratio--3x4.has-2[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 1.5);
}
.ratio--3x4[data-v-b6483216]::before {
  padding-top: 133.3333333333%;
}
.ratio--3x4.has-2[data-v-b6483216]::before {
  padding-top: 66.6666666667%;
}
.ratio--101x128[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 0.7890625);
}
.ratio--101x128.has-2[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 1.578125);
}
.ratio--101x128[data-v-b6483216]::before {
  padding-top: 126.7326732673%;
}
.ratio--101x128.has-2[data-v-b6483216]::before {
  padding-top: 63.3663366337%;
}
.ratio--575x325[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 1.7692307692);
}
.ratio--575x325.has-2[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 3.5384615385);
}
.ratio--575x325[data-v-b6483216]::before {
  padding-top: 56.5217391304%;
}
.ratio--575x325.has-2[data-v-b6483216]::before {
  padding-top: 28.2608695652%;
}
.ratio--800x450[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 1.7777777778);
}
.ratio--800x450.has-2[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 3.5555555556);
}
.ratio--800x450[data-v-b6483216]::before {
  padding-top: 56.25%;
}
.ratio--800x450.has-2[data-v-b6483216]::before {
  padding-top: 28.125%;
}
.ratio--1280x720[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 1.7777777778);
}
.ratio--1280x720.has-2[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 3.5555555556);
}
.ratio--1280x720[data-v-b6483216]::before {
  padding-top: 56.25%;
}
.ratio--1280x720.has-2[data-v-b6483216]::before {
  padding-top: 28.125%;
}
.ratio--1280x770[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 1.6623376623);
}
.ratio--1280x770.has-2[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 3.3246753247);
}
.ratio--1280x770[data-v-b6483216]::before {
  padding-top: 60.15625%;
}
.ratio--1280x770.has-2[data-v-b6483216]::before {
  padding-top: 30.078125%;
}
.ratio--800x680[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 1.1764705882);
}
.ratio--800x680.has-2[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 2.3529411765);
}
.ratio--800x680[data-v-b6483216]::before {
  padding-top: 85%;
}
.ratio--800x680.has-2[data-v-b6483216]::before {
  padding-top: 42.5%;
}
.ratio--800x625[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 1.28);
}
.ratio--800x625.has-2[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 2.56);
}
.ratio--800x625[data-v-b6483216]::before {
  padding-top: 78.125%;
}
.ratio--800x625.has-2[data-v-b6483216]::before {
  padding-top: 39.0625%;
}
.ratio--960x630[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 1.5238095238);
}
.ratio--960x630.has-2[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 3.0476190476);
}
.ratio--960x630[data-v-b6483216]::before {
  padding-top: 65.625%;
}
.ratio--960x630.has-2[data-v-b6483216]::before {
  padding-top: 32.8125%;
}
.ratio--960x600[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 1.6);
}
.ratio--960x600.has-2[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 3.2);
}
.ratio--960x600[data-v-b6483216]::before {
  padding-top: 62.5%;
}
.ratio--960x600.has-2[data-v-b6483216]::before {
  padding-top: 31.25%;
}
.ratio--1066x600[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 1.7766666667);
}
.ratio--1066x600.has-2[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 3.5533333333);
}
.ratio--1066x600[data-v-b6483216]::before {
  padding-top: 56.2851782364%;
}
.ratio--1066x600.has-2[data-v-b6483216]::before {
  padding-top: 28.1425891182%;
}
.ratio--1366x768[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 1.7786458333);
}
.ratio--1366x768.has-2[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 3.5572916667);
}
.ratio--1366x768[data-v-b6483216]::before {
  padding-top: 56.2225475842%;
}
.ratio--1366x768.has-2[data-v-b6483216]::before {
  padding-top: 28.1112737921%;
}
.ratio--1366x788[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 1.7335025381);
}
.ratio--1366x788.has-2[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 3.4670050761);
}
.ratio--1366x788[data-v-b6483216]::before {
  padding-top: 57.6866764275%;
}
.ratio--1366x788.has-2[data-v-b6483216]::before {
  padding-top: 28.8433382138%;
}
.ratio--1440x900[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 1.6);
}
.ratio--1440x900.has-2[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 3.2);
}
.ratio--1440x900[data-v-b6483216]::before {
  padding-top: 62.5%;
}
.ratio--1440x900.has-2[data-v-b6483216]::before {
  padding-top: 31.25%;
}
.ratio--1830x1200[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 1.525);
}
.ratio--1830x1200.has-2[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 3.05);
}
.ratio--1830x1200[data-v-b6483216]::before {
  padding-top: 65.5737704918%;
}
.ratio--1830x1200.has-2[data-v-b6483216]::before {
  padding-top: 32.7868852459%;
}
.ratio--1680x945[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 1.7777777778);
}
.ratio--1680x945.has-2[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 3.5555555556);
}
.ratio--1680x945[data-v-b6483216]::before {
  padding-top: 56.25%;
}
.ratio--1680x945.has-2[data-v-b6483216]::before {
  padding-top: 28.125%;
}
.ratio--1920x1200[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 1.6);
}
.ratio--1920x1200.has-2[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 3.2);
}
.ratio--1920x1200[data-v-b6483216]::before {
  padding-top: 62.5%;
}
.ratio--1920x1200.has-2[data-v-b6483216]::before {
  padding-top: 31.25%;
}
.ratio--1920x1260[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 1.5238095238);
}
.ratio--1920x1260.has-2[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 3.0476190476);
}
.ratio--1920x1260[data-v-b6483216]::before {
  padding-top: 65.625%;
}
.ratio--1920x1260.has-2[data-v-b6483216]::before {
  padding-top: 32.8125%;
}
.ratio--1920x1080[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 1.7777777778);
}
.ratio--1920x1080.has-2[data-v-b6483216] {
  max-width: calc((100vh - var(--magic-space--xx-large, 128px)) * 3.5555555556);
}
.ratio--1920x1080[data-v-b6483216]::before {
  padding-top: 56.25%;
}
.ratio--1920x1080.has-2[data-v-b6483216]::before {
  padding-top: 28.125%;
}
@media (min-width: 1024px) {
.ratio[data-v-b6483216] {
    width: calc(100vw - calc((var(--magic-space--xx-large, 128px) * 1.75) + var(--magic-menu-width--d, 230px)));
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.3333333333);
}
.ratio.has-2[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 2.6666666667);
}
.ratio--16x9[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.7777777778);
}
.ratio--16x9.has-2[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.5555555556);
}
.ratio--533x300[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.7766666667);
}
.ratio--533x300.has-2[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.5533333333);
}
.ratio--17x13[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.3076923077);
}
.ratio--17x13.has-2[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 2.6153846154);
}
.ratio--128x101[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.2673267327);
}
.ratio--128x101.has-2[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 2.5346534653);
}
.ratio--3x4[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 0.75);
}
.ratio--3x4.has-2[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.5);
}
.ratio--101x128[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 0.7890625);
}
.ratio--101x128.has-2[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.578125);
}
.ratio--575x325[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.7692307692);
}
.ratio--575x325.has-2[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.5384615385);
}
.ratio--800x450[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.7777777778);
}
.ratio--800x450.has-2[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.5555555556);
}
.ratio--1280x720[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.7777777778);
}
.ratio--1280x720.has-2[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.5555555556);
}
.ratio--1280x770[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.6623376623);
}
.ratio--1280x770.has-2[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.3246753247);
}
.ratio--800x680[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.1764705882);
}
.ratio--800x680.has-2[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 2.3529411765);
}
.ratio--800x625[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.28);
}
.ratio--800x625.has-2[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 2.56);
}
.ratio--960x630[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.5238095238);
}
.ratio--960x630.has-2[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.0476190476);
}
.ratio--960x600[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.6);
}
.ratio--960x600.has-2[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.2);
}
.ratio--1066x600[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.7766666667);
}
.ratio--1066x600.has-2[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.5533333333);
}
.ratio--1366x768[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.7786458333);
}
.ratio--1366x768.has-2[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.5572916667);
}
.ratio--1366x788[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.7335025381);
}
.ratio--1366x788.has-2[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.4670050761);
}
.ratio--1440x900[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.6);
}
.ratio--1440x900.has-2[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.2);
}
.ratio--1830x1200[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.525);
}
.ratio--1830x1200.has-2[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.05);
}
.ratio--1680x945[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.7777777778);
}
.ratio--1680x945.has-2[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.5555555556);
}
.ratio--1920x1200[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.6);
}
.ratio--1920x1200.has-2[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.2);
}
.ratio--1920x1260[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.5238095238);
}
.ratio--1920x1260.has-2[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.0476190476);
}
.ratio--1920x1080[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.7777777778);
}
.ratio--1920x1080.has-2[data-v-b6483216] {
    max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.5555555556);
}
}
.is-fullscreen .ratio[data-v-b6483216], .ratio.is-fullscreen[data-v-b6483216] {
  width: calc(100vw - calc((var(--magic-space--xx-large, 128px) * 1.75)));
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.3333333333);
}
.is-fullscreen .ratio.has-2[data-v-b6483216], .ratio.is-fullscreen.has-2[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 2.6666666667);
}
.is-fullscreen .ratio--16x9[data-v-b6483216], .ratio.is-fullscreen--16x9[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.7777777778);
}
.is-fullscreen .ratio--16x9.has-2[data-v-b6483216], .ratio.is-fullscreen--16x9.has-2[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.5555555556);
}
.is-fullscreen .ratio--533x300[data-v-b6483216], .ratio.is-fullscreen--533x300[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.7766666667);
}
.is-fullscreen .ratio--533x300.has-2[data-v-b6483216], .ratio.is-fullscreen--533x300.has-2[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.5533333333);
}
.is-fullscreen .ratio--17x13[data-v-b6483216], .ratio.is-fullscreen--17x13[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.3076923077);
}
.is-fullscreen .ratio--17x13.has-2[data-v-b6483216], .ratio.is-fullscreen--17x13.has-2[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 2.6153846154);
}
.is-fullscreen .ratio--128x101[data-v-b6483216], .ratio.is-fullscreen--128x101[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.2673267327);
}
.is-fullscreen .ratio--128x101.has-2[data-v-b6483216], .ratio.is-fullscreen--128x101.has-2[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 2.5346534653);
}
.is-fullscreen .ratio--3x4[data-v-b6483216], .ratio.is-fullscreen--3x4[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 0.75);
}
.is-fullscreen .ratio--3x4.has-2[data-v-b6483216], .ratio.is-fullscreen--3x4.has-2[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.5);
}
.is-fullscreen .ratio--101x128[data-v-b6483216], .ratio.is-fullscreen--101x128[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 0.7890625);
}
.is-fullscreen .ratio--101x128.has-2[data-v-b6483216], .ratio.is-fullscreen--101x128.has-2[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.578125);
}
.is-fullscreen .ratio--575x325[data-v-b6483216], .ratio.is-fullscreen--575x325[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.7692307692);
}
.is-fullscreen .ratio--575x325.has-2[data-v-b6483216], .ratio.is-fullscreen--575x325.has-2[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.5384615385);
}
.is-fullscreen .ratio--800x450[data-v-b6483216], .ratio.is-fullscreen--800x450[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.7777777778);
}
.is-fullscreen .ratio--800x450.has-2[data-v-b6483216], .ratio.is-fullscreen--800x450.has-2[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.5555555556);
}
.is-fullscreen .ratio--1280x720[data-v-b6483216], .ratio.is-fullscreen--1280x720[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.7777777778);
}
.is-fullscreen .ratio--1280x720.has-2[data-v-b6483216], .ratio.is-fullscreen--1280x720.has-2[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.5555555556);
}
.is-fullscreen .ratio--1280x770[data-v-b6483216], .ratio.is-fullscreen--1280x770[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.6623376623);
}
.is-fullscreen .ratio--1280x770.has-2[data-v-b6483216], .ratio.is-fullscreen--1280x770.has-2[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.3246753247);
}
.is-fullscreen .ratio--800x680[data-v-b6483216], .ratio.is-fullscreen--800x680[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.1764705882);
}
.is-fullscreen .ratio--800x680.has-2[data-v-b6483216], .ratio.is-fullscreen--800x680.has-2[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 2.3529411765);
}
.is-fullscreen .ratio--800x625[data-v-b6483216], .ratio.is-fullscreen--800x625[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.28);
}
.is-fullscreen .ratio--800x625.has-2[data-v-b6483216], .ratio.is-fullscreen--800x625.has-2[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 2.56);
}
.is-fullscreen .ratio--960x630[data-v-b6483216], .ratio.is-fullscreen--960x630[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.5238095238);
}
.is-fullscreen .ratio--960x630.has-2[data-v-b6483216], .ratio.is-fullscreen--960x630.has-2[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.0476190476);
}
.is-fullscreen .ratio--960x600[data-v-b6483216], .ratio.is-fullscreen--960x600[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.6);
}
.is-fullscreen .ratio--960x600.has-2[data-v-b6483216], .ratio.is-fullscreen--960x600.has-2[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.2);
}
.is-fullscreen .ratio--1066x600[data-v-b6483216], .ratio.is-fullscreen--1066x600[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.7766666667);
}
.is-fullscreen .ratio--1066x600.has-2[data-v-b6483216], .ratio.is-fullscreen--1066x600.has-2[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.5533333333);
}
.is-fullscreen .ratio--1366x768[data-v-b6483216], .ratio.is-fullscreen--1366x768[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.7786458333);
}
.is-fullscreen .ratio--1366x768.has-2[data-v-b6483216], .ratio.is-fullscreen--1366x768.has-2[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.5572916667);
}
.is-fullscreen .ratio--1366x788[data-v-b6483216], .ratio.is-fullscreen--1366x788[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.7335025381);
}
.is-fullscreen .ratio--1366x788.has-2[data-v-b6483216], .ratio.is-fullscreen--1366x788.has-2[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.4670050761);
}
.is-fullscreen .ratio--1440x900[data-v-b6483216], .ratio.is-fullscreen--1440x900[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.6);
}
.is-fullscreen .ratio--1440x900.has-2[data-v-b6483216], .ratio.is-fullscreen--1440x900.has-2[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.2);
}
.is-fullscreen .ratio--1830x1200[data-v-b6483216], .ratio.is-fullscreen--1830x1200[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.525);
}
.is-fullscreen .ratio--1830x1200.has-2[data-v-b6483216], .ratio.is-fullscreen--1830x1200.has-2[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.05);
}
.is-fullscreen .ratio--1680x945[data-v-b6483216], .ratio.is-fullscreen--1680x945[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.7777777778);
}
.is-fullscreen .ratio--1680x945.has-2[data-v-b6483216], .ratio.is-fullscreen--1680x945.has-2[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.5555555556);
}
.is-fullscreen .ratio--1920x1200[data-v-b6483216], .ratio.is-fullscreen--1920x1200[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.6);
}
.is-fullscreen .ratio--1920x1200.has-2[data-v-b6483216], .ratio.is-fullscreen--1920x1200.has-2[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.2);
}
.is-fullscreen .ratio--1920x1260[data-v-b6483216], .ratio.is-fullscreen--1920x1260[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.5238095238);
}
.is-fullscreen .ratio--1920x1260.has-2[data-v-b6483216], .ratio.is-fullscreen--1920x1260.has-2[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.0476190476);
}
.is-fullscreen .ratio--1920x1080[data-v-b6483216], .ratio.is-fullscreen--1920x1080[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 1.7777777778);
}
.is-fullscreen .ratio--1920x1080.has-2[data-v-b6483216], .ratio.is-fullscreen--1920x1080.has-2[data-v-b6483216] {
  max-width: calc((100vh - calc(var(--magic-space--xx-large, 128px) * 1.75)) * 3.5555555556);
}
.slots[data-v-b6483216] {
  position: relative;
}
.is-full-page .slots[data-v-b6483216] {
  display: contents;
}
.is-full-page.is-minimized .slots[data-v-b6483216] {
  display: block;
}
.slots.has-1 .slot[data-v-b6483216] {
  width: 100%;
  height: 100%;
}
.slots.has-1 .slot--1[data-v-b6483216] {
  top: 0;
  left: 0;
}
.slots.has-2 .slot[data-v-b6483216] {
  width: 50%;
  height: 100%;
}
.slots.has-2 .slot--1[data-v-b6483216] {
  top: 0;
  left: 0;
}
.slots.has-2 .slot--2[data-v-b6483216] {
  top: 0;
  left: 50%;
}
.slots.has-4 .slot[data-v-b6483216] {
  width: 50%;
  height: 50%;
}
.slots.has-4 .slot--1[data-v-b6483216] {
  top: 0;
  left: 0;
}
.slots.has-4 .slot--2[data-v-b6483216] {
  top: 0;
  left: 50%;
}
.slots.has-4 .slot--3[data-v-b6483216] {
  top: 50%;
  left: 0;
}
.slots.has-4 .slot--4[data-v-b6483216] {
  top: 50%;
  left: 50%;
}
.slot[data-v-b6483216] {
  position: absolute;
  overflow: hidden;
}
.is-full-page .slot[data-v-b6483216] {
  display: contents;
}
.is-full-page.is-minimized .slot[data-v-b6483216] {
  display: block;
}
.slot[data-v-b6483216]::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 0;
}
.continue-playing[data-v-b6483216] {
  position: fixed;
  bottom: calc(var(--magic-bottom-navigation-height, 64px) + var(--magic-space, 16px));
  right: var(--magic-space, 16px);
  padding: var(--magic-space--small, 8px) var(--magic-space, 16px) var(--magic-space, 16px);
  border-radius: var(--magic-border-radius, 4px);
  background-color: var(--magic-light, #fff);
  box-shadow: var(--magic-shadow-4, 0 18px 18px 0 var(--magic-shadow, rgba(10, 31, 68, 0.12)), 0 0 1px 0 var(--magic-shadow-light, rgba(10, 31, 68, 0.1)), 0 0 0 0.2px var(--magic-shadow-lighter, rgba(10, 31, 68, 0.08)));
}
@supports (padding-bottom: constant(safe-area-inset-bottom)) {
.continue-playing[data-v-b6483216] {
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    bottom: calc(var(--safe-area-inset-bottom) + var(--magic-bottom-navigation-height, 64px) + var(--magic-space, 16px));
}
}
@supports (padding-bottom: env(safe-area-inset-bottom)) {
.continue-playing[data-v-b6483216] {
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    bottom: calc(var(--safe-area-inset-bottom) + var(--magic-bottom-navigation-height, 64px) + var(--magic-space, 16px));
}
}
@media (min-width: 1024px) {
.continue-playing[data-v-b6483216] {
    bottom: var(--magic-space, 16px);
}
}
.title[data-v-b6483216] {
  font-size: 1rem;
  font-weight: var(--magic-font-weight-semibold, 600);
  display: block;
  margin: 0 0 var(--magic-space--small, 8px);
  max-width: calc(var(--magic-game-minimized-width, 204px) - (var(--magic-icon-size, 24px) / 2));
  color: var(--magic-dark, #000);
}
.icon[data-v-b6483216] {
  display: block;
  position: absolute;
  top: var(--magic-space--small, 8px);
  right: var(--magic-space--small, 8px);
  width: var(--magic-icon-size, 24px);
  height: var(--magic-icon-size, 24px);
  color: var(--magic-dark, #000);
  cursor: pointer;
}
.wrapper[data-v-b6483216] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: var(--magic-game-minimized-width, 204px);
  height: 100%;
}
.slots[data-v-b6483216] {
  transition: all var(--magic-duration, 0.3s) ease;
  will-change: all;
}
.slot[data-v-b6483216]::before {
  background-color: var(--magic-dark, #000);
}