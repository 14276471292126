.header[data-v-54c39f5e] {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--magic-content-size-faq, 735px);
}
.content[data-v-54c39f5e],
.wrapper[data-v-54c39f5e] {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: var(--magic-content-size-faq, 735px);
}
@media (min-width: 1024px) {
.content--top-nav[data-v-54c39f5e] {
    max-width: var(--magic-content-size-faq-top-nav, 1116px);
}
}
.title[data-v-54c39f5e] {
  display: none;
}
@media (min-width: 1024px) {
.title[data-v-54c39f5e] {
    font-size: 1.75rem;
    font-weight: var(--magic-font-weight-semibold, 600);
    display: inline-block;
    position: absolute;
    top: calc((var(--magic-space--x-small, 4px) + var(--magic-space--xx-small, 2px)) * -1);
    left: 0;
    margin: 0;
    line-height: 1;
}
}