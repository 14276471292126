.footer[data-v-bb19674e] {
  position: relative;
  padding: var(--magic-space--large, 32px);
  padding-bottom: calc(var(--magic-space--large, 32px) + var(--magic-bottom-navigation-height, 64px));
  background-color: var(--magic-tertiary, #141f33);
  box-shadow: inset 0 1px 0 0 var(--magic-footer-light-transparent, rgba(255, 255, 255, 0.1));
  text-align: center;
  color: var(--magic-tertiary-contrast, #fff);
}
@media (min-width: 1400px) {
.footer[data-v-bb19674e] {
    padding: var(--magic-space--large, 32px) calc(var(--magic-space--large, 32px) * 6);
}
}
.is-fullscreen .footer[data-v-bb19674e], .footer.is-fullscreen[data-v-bb19674e] {
  display: none;
}
.logo[data-v-bb19674e] {
  background-image: url(../img/logo.c333aac4.svg);
  background-repeat: no-repeat;
  width: 205.5454545455px;
  height: 38px;
  background-size: 100% 100%;
  position: relative;
  margin: 0 auto var(--magic-space--large, 32px);
}
.navigation[data-v-bb19674e] {
  font-weight: var(--magic-font-weight-semibold, 600);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto calc(var(--magic-space--large, 32px) - var(--magic-space--small, 8px));
  width: 100%;
  max-width: var(--magic-footer-max-width, 800px);
}
.title[data-v-bb19674e] {
  font-size: 1rem;
  margin: 0 0 var(--magic-space--small, 8px);
}
.text[data-v-bb19674e] {
  font-size: 0.75rem;
  margin: 0 0 var(--magic-space--large, 32px);
}
.link[data-v-bb19674e] {
  margin: var(--magic-space--small, 8px) calc(var(--magic-space--small, 8px) + var(--magic-space--xx-small, 2px));
  text-decoration: none;
}
.link--logo[data-v-bb19674e] {
  font-size: 0;
  display: block;
  flex: 1 0 100%;
  margin: var(--magic-space--small, 8px);
  width: 100%;
  height: 100%;
}
.navigation .link[data-v-bb19674e] {
  color: #00eaab;
  font-weight: 900;
}
.social-media-logo .link[data-v-bb19674e] {
  display: block;
  flex: initial;
  margin: 0;
  width: auto;
  height: auto;
}
.icon[data-v-bb19674e] {
  height: var(--magic-footer-logo-size, var(--magic-icon-size, 24px));
}
.icon--gamstop[data-v-bb19674e], .icon--neteller[data-v-bb19674e], .icon--visa[data-v-bb19674e] {
  height: calc(var(--magic-footer-logo-size, var(--magic-icon-size, 24px)) * .65);
}
.icon--spelinspektionen[data-v-bb19674e] {
  height: var(--magic-footer-logo-size-large, 40px);
}
.wrapper[data-v-bb19674e] {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  max-width: var(--magic-footer-max-width, 800px);
}
.safety[data-v-bb19674e],
.payment[data-v-bb19674e] {
  margin: var(--magic-space, 16px) 0;
  width: 100%;
}
@media (min-width: 1024px) {
.safety[data-v-bb19674e],
.payment[data-v-bb19674e] {
    margin: var(--magic-space--small, 8px) 0;
    width: 50%;
}
}
.social-media[data-v-bb19674e] {
  margin: 0 auto var(--magic-space--large, 32px);
}
.legal[data-v-bb19674e] {
  margin: var(--magic-space, 16px) 0;
  width: 100%;
}
.social-media-logos[data-v-bb19674e],
.safety-logos[data-v-bb19674e],
.payment-logos[data-v-bb19674e],
.legal-logos[data-v-bb19674e] {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 var(--magic-space, 16px);
  width: 100%;
}
@media (min-width: 1024px) {
.social-media-logos[data-v-bb19674e],
.safety-logos[data-v-bb19674e],
.payment-logos[data-v-bb19674e],
.legal-logos[data-v-bb19674e] {
    padding: 0 var(--magic-space--large, 32px);
}
}
.social-media-logos[data-v-bb19674e] {
  column-gap: 26px;
}
.social-media-logo[data-v-bb19674e],
.safety-logo[data-v-bb19674e],
.payment-logo[data-v-bb19674e],
.legal-logo[data-v-bb19674e] {
  font-size: 0;
}
.social-media-logo[data-v-bb19674e] {
  color: var(--magic-light, #fff);
}
.safety-logo[data-v-bb19674e] {
  color: var(--magic-light, #fff);
}
.payment-logo[data-v-bb19674e] {
  filter: brightness(0%) invert(100%);
}
.version[data-v-bb19674e] {
  font-size: 0.69rem;
  font-weight: var(--magic-font-weight-semibold, 600);
  position: absolute;
  bottom: calc(var(--magic-bottom-navigation-height, 64px) + var(--magic-space, 16px) + var(--magic-space--xxx-small, 1px));
  right: calc(var(--magic-space, 16px) + var(--magic-space--x-small, 4px));
  margin: 0;
  opacity: 0.55;
}
@media (min-width: 1024px) {
.version[data-v-bb19674e] {
    bottom: calc(var(--magic-space, 16px) + var(--magic-space--xxx-small, 1px));
}
}