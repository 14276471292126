.game-frame[data-v-092c837f] {
  position: absolute;
  top: var(--magic-game-frame-position-top, auto);
  left: var(--magic-game-frame-position-left, auto);
  bottom: auto;
  right: auto;
  transform-origin: center center;
  transition: transform var(--magic-duration, 0.3s) ease;
  will-change: transform;
}
.game-frame.is-full-page[data-v-092c837f] {
  position: fixed;
  top: calc(var(--magic-offset-top) - var(--magic-top-navigation-height, 56px));
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  min-height: auto;
  z-index: 3;
}
@media (min-width: 1024px) {
.game-frame.is-full-page[data-v-092c837f] {
    top: var(--magic-offset-top);
}
}
.game-frame.is-minimized[data-v-092c837f] {
  position: fixed;
  top: auto;
  left: auto;
  bottom: calc(var(--magic-bottom-navigation-height, 64px) + var(--magic-space--large, 32px));
  right: var(--magic-space--large, 32px);
  transform-origin: bottom right;
  transform: scale(var(--magic-game-frame-scale, 1));
}
@supports (padding-bottom: constant(safe-area-inset-bottom)) {
.game-frame.is-minimized[data-v-092c837f] {
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    bottom: calc(var(--safe-area-inset-bottom) + var(--magic-bottom-navigation-height, 64px) + var(--magic-space--large, 32px));
}
}
@supports (padding-bottom: env(safe-area-inset-bottom)) {
.game-frame.is-minimized[data-v-092c837f] {
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    bottom: calc(var(--safe-area-inset-bottom) + var(--magic-bottom-navigation-height, 64px) + var(--magic-space--large, 32px));
}
}
@media (min-width: 1024px) {
.game-frame.is-minimized[data-v-092c837f] {
    bottom: var(--magic-space--large, 32px);
}
}