.app[data-v-5b61343b],
.content[data-v-5b61343b] {
  width: 100%;
  height: 100%;
}
.app[data-v-5b61343b] {
  --magic-offset-top: var(--magic-top-navigation-height, 56px);
  --magic-offset-bottom: var(--magic-bottom-navigation-height, 64px);
  --magic-top-nav-offset-top: calc(var(--magic-top-navigation-height, 56px) + var(--magic-bottom-navigation-height, 64px));
  --magic-offset-left: 0px;
  --magic-offset-right: 0px;
  display: flex;
  background-color: var(--magic-background, #fbfbfb);
  transition: background-color var(--magic-duration, 0.3s) ease;
  will-change: background-color;
}
.app.is-sga[data-v-5b61343b] {
  --magic-offset-top: calc(var(--magic-top-navigation-height, 56px) + var(--magic-sga-bar-height--m, 50px));
}
.app.is-game-play-mobile[data-v-5b61343b] {
  --magic-offset-top: 0px;
}
.app.is-game-play-mobile-session[data-v-5b61343b] {
  --magic-offset-top: var(--magic-game-net-position-bar-height, 26px);
}
.app.is-lotba[data-v-5b61343b] {
  --magic-offset-top: calc(var(--magic-top-navigation-height, 56px) + var(--magic-lotba-bar-height--m, 70px));
}
.app.is-lotba.is-game-play-mobile[data-v-5b61343b] {
  --magic-offset-top: var(--magic-lotba-bar-height--m, 70px);
}
.app.is-agco[data-v-5b61343b] {
  --magic-offset-top: calc(var(--magic-top-navigation-height, 56px) + var(--magic-agco-bar-height--m, 32px));
}
.app.is-agco.is-game-play-mobile[data-v-5b61343b] {
  --magic-offset-top: var(--magic-agco-bar-height--m, 32px);
}
.app.is-agco.is-game-play-mobile-session[data-v-5b61343b] {
  --magic-offset-top: calc(var(--magic-agco-bar-height--m, 32px) + var(--magic-game-net-position-bar-height, 26px));
  --magic-game-session-bar-top-offset: var(--magic-agco-bar-height--m, 32px);
}
.app.is-emta[data-v-5b61343b] {
  --magic-offset-top: calc(var(--magic-top-navigation-height, 56px) + var(--magic-emta-bar-height--m, 32px));
}
.app.is-emta.is-game-play-mobile[data-v-5b61343b] {
  --magic-offset-top: var(--magic-emta-bar-height--m, 32px);
}
.app.is-emta.is-game-play-mobile-session[data-v-5b61343b] {
  --magic-offset-top: calc(var(--magic-emta-bar-height--m, 32px) + var(--magic-game-net-position-bar-height, 26px));
  --magic-game-session-bar-top-offset: var(--magic-emta-bar-height--m, 32px);
}
.app.is-menu-hidden .main-navigation[data-v-5b61343b] {
  display: none;
}
.app.is-menu-hidden .content[data-v-5b61343b] {
  padding-left: 0;
}
.app.is-sportsbook-page-mobile[data-v-5b61343b] {
  --magic-offset-bottom: var(--magic-sportsbook-navigation-height, 50px);
}
.app.is-top-navigation-mobile[data-v-5b61343b] {
  --magic-offset-bottom: 0px;
}
.app.is-sga[data-v-5b61343b] {
  --magic-offset-top: calc(var(--magic-top-navigation-height, 56px) + var(--magic-sga-bar-height--m, 50px));
}
@media (min-width: 1024px) {
.app[data-v-5b61343b] {
    --magic-offset-top: 0px;
    --magic-offset-bottom: 0px;
    --magic-offset-left: var(--magic-menu-width--d, 230px);
}
.app.is-sga[data-v-5b61343b] {
    --magic-offset-top: var(--magic-sga-bar-height--d, 44px);
}
.app.is-sga.is-fullscreen[data-v-5b61343b] {
    --magic-offset-top: var(--magic-sga-bar-height--d, 44px);
    --magic-offset-left: 0px;
}
.app.is-lotba[data-v-5b61343b] {
    --magic-offset-top: var(--magic-lotba-bar-height--d, 50px);
}
.app.is-lotba.is-fullscreen[data-v-5b61343b] {
    --magic-offset-top: var(--magic-lotba-bar-height--d, 50px);
    --magic-offset-left: 0px;
}
.app.is-agco[data-v-5b61343b] {
    --magic-offset-top: var(--magic-agco-bar-height--d, 32px);
}
.app.is-agco.is-fullscreen[data-v-5b61343b] {
    --magic-offset-top: var(--magic-agco-bar-height--d, 32px);
    --magic-offset-left: 0px;
}
.app.is-emta[data-v-5b61343b] {
    --magic-offset-top: var(--magic-emta-bar-height--d, 32px);
}
.app.is-emta.is-fullscreen[data-v-5b61343b] {
    --magic-offset-top: var(--magic-emta-bar-height--d, 32px);
    --magic-offset-left: 0px;
}
}
.is-fullscreen .app[data-v-5b61343b], .app.is-fullscreen[data-v-5b61343b] {
  --magic-offset-top: 0px;
  --magic-offset-left: 0px;
}
.content[data-v-5b61343b] {
  position: relative;
  padding: var(--magic-offset-top) var(--magic-offset-right) var(--magic-offset-bottom) var(--magic-offset-left);
  transition: padding-top var(--magic-duration, 0.3s) ease;
  will-change: padding-top;
}
.content--top-nav[data-v-5b61343b] {
  --magic-content-size: 1344px;
  --magic-offset-top: var(--magic-top-nav-height--m, 110px);
  --magic-offset-left: 0;
}
.is-sga .content--top-nav[data-v-5b61343b] {
  --magic-offset-top: calc(var(--magic-top-nav-height--m, 110px) + var(--magic-sga-bar-height--m, 50px));
}
.is-lotba .content--top-nav[data-v-5b61343b] {
  --magic-offset-top: calc(var(--magic-top-nav-height--m, 110px) + var(--magic-lotba-bar-height--m, 70px));
}
.is-agco .content--top-nav[data-v-5b61343b] {
  --magic-offset-top: calc(var(--magic-top-nav-height--m, 110px) + var(--magic-agco-bar-height--m, 32px));
}
@media (min-width: 1024px) {
.content--top-nav[data-v-5b61343b] {
    --magic-offset-top: var(--magic-top-nav-height--d, 80px);
}
.is-sga .content--top-nav[data-v-5b61343b] {
    --magic-offset-top: calc(var(--magic-top-nav-height--d, 80px) + var(--magic-sga-bar-height--d, 44px));
}
.is-lotba .content--top-nav[data-v-5b61343b] {
    --magic-offset-top: calc(var(--magic-top-nav-height--d, 80px) + var(--magic-lotba-bar-height--d, 50px));
}
.is-agco .content--top-nav[data-v-5b61343b] {
    --magic-offset-top: calc(var(--magic-top-nav-height--d, 80px) + var(--magic-agco-bar-height--d, 32px));
}
}
.content--top-nav-light[data-v-5b61343b] {
  padding: var(--magic-top-navigation-height, 56px) 0 0;
}
.is-sga .content--top-nav-light[data-v-5b61343b] {
  padding: calc(var(--magic-top-navigation-height, 56px) + var(--magic-sga-bar-height--m, 50px)) 0 0;
}
.is-lotba .content--top-nav-light[data-v-5b61343b] {
  padding: calc(var(--magic-top-navigation-height, 56px) + var(--magic-lotba-bar-height--m, 70px)) 0 0;
}
.is-agco .content--top-nav-light[data-v-5b61343b] {
  padding: calc(var(--magic-top-navigation-height, 56px) + var(--magic-agco-bar-height--m, 32px)) 0 0;
}
.page[data-v-5b61343b] {
  padding: var(--magic-space, 16px);
  min-height: calc(100vh - calc(var(--magic-offset-top) + var(--magic-offset-bottom)));
}