.container[data-v-304aefc1] {
  opacity: 1;
  will-change: opacity;
  --magic-modal-background: var(--magic-dark, #000);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: fixed;
  top: calc(var(--magic-offset-top) - var(--magic-top-navigation-height, 56px));
  left: 0;
  width: 100%;
  height: calc(100% - (var(--magic-offset-top) - var(--magic-top-navigation-height, 56px) + var(--magic-offset-bottom)));
  background: var(--magic-background, #fbfbfb);
  overflow-y: auto;
  z-index: 2;
}
.container.fade-in-enter-active[data-v-304aefc1], .container.fade-in-leave-active[data-v-304aefc1] {
  transition: opacity var(--magic-duration, 0.3s) ease;
}
.container.fade-in-enter[data-v-304aefc1], .container.fade-in-leave-to[data-v-304aefc1] {
  opacity: 0;
}
.container--mobile-gameplay[data-v-304aefc1] {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  z-index: 4;
}
.container--borderless[data-v-304aefc1] {
  top: var(--magic-offset-top);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: var(--magic-space--large, 32px) calc(var(--magic-space, 16px) + (var(--magic-space--small, 8px) - var(--magic-space--xx-small, 2px))) var(--magic-space--x-large, 64px);
  height: calc(100% - (var(--magic-offset-top) + var(--magic-offset-bottom)));
  background: var(--magic-modal-container-background, rgba(0, 0, 0, 0.6));
}
@media (min-width: 1024px) {
.container[data-v-304aefc1] {
    --magic-modal-background: var(--magic-light, #fff);
    top: var(--magic-offset-top);
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: var(--magic-space--large, 32px) var(--magic-space--large, 32px) var(--magic-space--x-large, 64px);
    height: calc(100% - (var(--magic-offset-top) + var(--magic-offset-bottom)));
    background: var(--magic-modal-container-background, rgba(0, 0, 0, 0.6));
}
}
.element[data-v-304aefc1] {
  width: 100%;
}
.element--borderless[data-v-304aefc1] {
  width: auto;
}
@media (min-width: 1024px) {
.element[data-v-304aefc1] {
    display: flex;
    width: unset;
    max-height: 100%;
    animation: fade-in-animation-data-v-304aefc1 var(--magic-duration, 0.3s) ease forwards;
    will-change: transform, opacity;
}
}
.title[data-v-304aefc1] {
  font-size: 1rem;
  margin: auto;
  width: 100%;
  text-align: center;
  line-height: var(--magic-space--large, 32px);
  color: var(--magic-light, #fff);
}
@media (min-width: 1024px) {
.title[data-v-304aefc1] {
    color: var(--magic-tertiary, #141f33);
}
}
@keyframes fade-in-animation-data-v-304aefc1 {
0% {
    opacity: 0;
    transform: translate3d(0, calc(var(--magic-space, 16px) * -1), 0);
}
100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}
}