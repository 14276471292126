.not-found[data-v-601e2bc5] {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.image[data-v-601e2bc5] {
  margin: 0 auto;
  width: auto;
  height: auto;
  max-width: 100%;
}
@media (min-width: 768px) {
.image[data-v-601e2bc5] {
    max-width: var(--magic-modal-size-large, 700px);
}
}
.title[data-v-601e2bc5] {
  font-size: 1.25rem;
  font-weight: var(--magic-font-weight-semibold, 600);
  margin: 0 auto var(--magic-space--small, 8px);
  color: var(--magic-header, #161616);
}
.text[data-v-601e2bc5] {
  font-size: 1rem;
  margin: 0 auto var(--magic-space, 16px);
}
.action[data-v-601e2bc5] {
  margin: 0 auto;
  max-width: var(--magic-element-default-width, 288px);
}